import { Link } from '@reach/router'
import { OrderLineFieldsFragment, StockStatus, AccountCode } from 'generated'
import cn from 'classnames'
import { gql, useMutation } from '@apollo/client'
import { Alert, Badge, Button } from '@plusplusminus/plusplusdash'
import AccountingLines from 'components/AccountingLines'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { getImageUrl } from 'utils'
import RemoveItemModal from './RemoveItemModal'
import RefundPayoutDate from 'pages/Refunds/RefundPayoutDate'

interface LineItemProps {
  accountingCodes: AccountCode[]
  onRefetch: () => void
  lineItem: OrderLineFieldsFragment
  isMinimal?: boolean
  refetch?: () => void
  orderId: string
  isCompleted?: boolean
}

const orderLineStatusColors: Record<string, React.ComponentProps<typeof Badge>['color']> = {
  Pending: 'yellow',
  Processing: 'blue',
  Completed: 'green',
  Fulfilled: 'blue',
  Refunded: 'blue',
  Cancelled: 'red'
}

const stockStatusColors: Record<string, React.ComponentProps<typeof Badge>['color']> = {
  [StockStatus.InStock]: 'green',
  [StockStatus.LowStock]: 'yellow',
  [StockStatus.OutOfStock]: 'red',
  [StockStatus.ProductRemoved]: 'red'
}

const STOCK_STATUS_MAP: Partial<Record<StockStatus, string>> = {
  [StockStatus.InStock]: 'In stock',
  [StockStatus.LowStock]: 'Low stock',
  [StockStatus.OutOfStock]: 'Out of stock',
  [StockStatus.ProductRemoved]: 'Product removed'
}

const productStatusColors: Record<string, React.ComponentProps<typeof Badge>['color']> = {
  active: 'green',
  draft: 'yellow'
}

const PRODUCT_STATUS_MAP: Partial<Record<string, string>> = {
  draft: 'Draft',
  active: 'Active'
}

const LineItem = (props: LineItemProps): React.ReactElement => {
  const { lineItem, orderId, isMinimal = false, accountingCodes, onRefetch, isCompleted = true } = props
  const [isOpen, setIsOpen] = useState(false)
  const [removeItem, { loading }] = useMutation(REMOVE_ITEM_MUTATION, {
    onCompleted: () => {
      toast.success('Successfully removed item')
      setIsOpen(false)
      if (onRefetch) {
        onRefetch()
      }
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could remove item')
    }
  })

  const shippingMethod = lineItem.shippingMethods.find(({ id }) => id === lineItem.shippingHandle)
  return (
    <div>
      <div className={cn({ 'border border-gray-300 rounded-md mb-5': !isMinimal })}>
        {!isMinimal ? (
          <div>
            <div className="border-b border-gray-300 p-3 ">
              <div className="w-full flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Link to={`/brands/${lineItem.brand.id}`} className="text-md font-medium underline">
                    {lineItem.brand.brandName}
                  </Link>
                  <div className="text-sm font-medium pl-2">
                    <Badge color={orderLineStatusColors[lineItem.status]}>{lineItem.status}</Badge>
                  </div>
                  <RefundPayoutDate
                    orderLineId={lineItem?.id ?? ''}
                    currentPaymentDate={lineItem?.paymentDate}
                    isPayout={false}
                    onRefetch={onRefetch}
                  />
                </div>
              </div>

              {lineItem.issues.error && (
                <div className="mt-2">
                  <Alert type="error">
                    <Alert.Heading>{lineItem.issues.error}</Alert.Heading>
                  </Alert>
                </div>
              )}
            </div>
          </div>
        ) : null}
        <div className="p-3 pb-0">
          {lineItem.items.map((item) => {
            const isProductRemoved = item.productVariant.stockStatus === StockStatus.ProductRemoved || !item.productVariant.product
            return (
              <div>
                <RemoveItemModal
                  isLoading={loading}
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  name={item.productVariant.title}
                  removeItem={() =>
                    removeItem({
                      variables: {
                        orderId: props.orderId,
                        input: {
                          lineId: lineItem.id,
                          productVariantId: item.productVariant.id
                        }
                      }
                    })
                  }
                />
                <div className="border-b border-gray-200 py-2">
                  <div className="flex">
                    {item.productVariant.product?.image ? (
                      <img
                        className="w-16 h-16 object-cover mr-2"
                        src={getImageUrl(item.productVariant.product.image, 130)}
                      />
                    ) : null}
                    <div>
                      <div className="flex items-center mb-2">
                        {!isProductRemoved ? (
                          <Link
                            to={`/products/${item.productVariant.product?.id}`}
                            className="text-sm font-medium pr-2 border-r border-gray-400 underline"
                          >
                            {item.productVariant.product?.productTitle}
                          </Link>
                        ) : (
                          <div className="text-sm font-medium pr-2 border-r border-gray-400">
                            {item.productVariant.product?.productTitle}
                          </div>
                        )}
                        <div className="text-sm font-medium pl-2 border-r border-gray-400 pr-2">
                          {item.productVariant.title}
                        </div>

                        <div
                          className={cn('text-sm font-medium pl-2 border-gray-400 pr-2', {
                            ' border-r': !isProductRemoved
                          })}
                        >
                          <Badge color={stockStatusColors[item.productVariant.stockStatus]}>
                            {STOCK_STATUS_MAP[item.productVariant.stockStatus]}
                          </Badge>
                        </div>
                        {!isProductRemoved && (
                          <div className="text-sm font-medium pl-2">
                            <Badge color={productStatusColors[item.productVariant.product?.status ?? 'active']}>
                              {PRODUCT_STATUS_MAP[item.productVariant.product?.status ?? 'active']}
                            </Badge>
                          </div>
                        )}
                      </div>

                      <div className="text-xs text-gray-500">Quantity: {item.quantity}</div>
                      <div className="text-sm">R {item.price}</div>
                      {(!isCompleted || isProductRemoved) && (
                        <div className="pt-4">
                          <Button variant="secondary" colorScheme="red" onClick={() => setIsOpen(true)}>
                            Remove item
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          <div className="flex flex-col-reverse xl:flex-row py-3 space-x-3 justify-end xl:justify-between text-md font-medium">
            {isCompleted && (
              <div className="my-3 xl:my-0">
                <AccountingLines
                  orderLineId={lineItem.id}
                  currentAccountingLines={lineItem.ledgerItems}
                  accountingCodes={accountingCodes}
                  invoiceUrl={lineItem?.invoiceUrl ?? undefined}
                  paymentAdviceUrl={lineItem?.paymentAdviceUrl ?? undefined}
                  onRefetch={onRefetch}
                />
              </div>
            )}
            <div className="w-1/4 self-end xl:self-auto">
              {shippingMethod ? <p className="text-sm mb-4">Shipping: {shippingMethod.title}</p> : null}
              <div className="flex justify-between text-sm">
                <span>Order Status</span>
                <span>{lineItem.orderStatus?.status ?? '-'}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Shipping Status</span>
                <span>{lineItem.orderStatus?.shippingStatus ?? '-'}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Shipping Total</span>
                <span>R {lineItem.shippingTotal?.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-md font-medium">
                <span>Subtotal</span>
                <div>R {lineItem.subTotal.toFixed(2)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LineItem

const REMOVE_ITEM_MUTATION = gql`
  mutation RemoveItem($orderId: String!, $input: RemoveItemInput!) {
    removeItemFromOrder(orderId: $orderId, input: $input) {
      id
    }
  }
`
